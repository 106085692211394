import { useMemo } from "react";

import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";

import { Sidebar } from "@destination/components";

import HomeIcon from "@icons/home.svg";
import ChargerIcon from "@icons/ico_charger_16.svg";
import SignOutIcon from "@icons/signOut.svg";
import SoftwareManagementIcon from "@icons/software_management_icon.svg";
import DocumentationIcon from "@icons/documentation.svg";

import { MenuItem } from "./MenuItem";

const documentationUrl = import.meta.env.VITE_APP_DOCUMENTATION_URL as string;

export const NavigationMenu = () => {
  const { t } = useTranslation();
  const navigationItems = useMemo(() => {
    const items = [
      {
        icon: <HomeIcon />,
        label: t("home.nav-item.label"),
        url: "/",
        exact: true,
        id: "home",
      },
      {
        icon: <ChargerIcon />,
        label: t("all-devices.nav-item.label"),
        url: "/fota/devices",
        id: "devices",
      },
      {
        icon: <SoftwareManagementIcon />,
        label: t("software-management.nav-item.label"),
        url: "/fota/software-packages",
        id: "softwarePackages",
      },
      {
        icon: <ChargerIcon />,
        label: t("deployment-rings.nav-item.label"),
        url: "/fota/deployment-rings",
        id: "deploymentRings",
      },
    ];
    return items;
  }, [t]);

  return <Navigation items={navigationItems} />;
};

export const NavigationFooter = () => {
  const { instance } = useMsal();
  const { t } = useTranslation();

  const handleLogout = () => {
    instance.logout();
  };

  const footerItems = [
    {
      id: "documentation",
      icon: <DocumentationIcon />,
      label: t("documentation.nav-item.label"),
      url: documentationUrl,
      target: "_blank",
      onMenuClick: () => {},
    },
    {
      id: "sign-out",
      icon: <SignOutIcon />,
      label: t("auth.sign-out.nav-item.label"),
      url: "/sign-out",
      onMenuClick: handleLogout,
    },
  ];

  return <Navigation items={footerItems} />;
};

interface NavigationItemProps {
  id: string;
  icon: React.ReactNode;
  label: string;
  url: string;
  exact?: boolean;
  onMenuClick?: () => void;
  target?: string;
}

interface NavigationProps {
  items: NavigationItemProps[];
}

const Navigation = ({ items }: NavigationProps) => {
  return (
    <nav className="flex h-full flex-col justify-between">
      {items.map(({ id, icon, label, target, url, exact, onMenuClick }) => (
        <MenuItem
          key={`menu-item-key-${id}`}
          dataTestId={`navigation-menu-item-${id}`}
          href={url}
          target={target}
          exact={exact}
          onClick={onMenuClick}
        >
          {({ isActive }) => (
            <Sidebar.MenuItem
              key={`navigation-${id}`}
              icon={icon}
              label={label}
              isActive={isActive}
            />
          )}
        </MenuItem>
      ))}
    </nav>
  );
};
